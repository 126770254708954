import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  // MuiTableCell,
} from "@material-ui/core";

import CustomTablehead from "./tableHead";
import "./styles.scss";

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === "string") {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(rows, order, orderBy, headCells) {
  let cc_headCell = (headCells || []).filter((a) => a["originalKey"] === orderBy ?? a)[0];
  let sorted = rows.sort((a, b) =>
    !cc_headCell?.["numeric"]
      ? order == "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]
      : order == "asc" ? b[orderBy].localeCompare(a[orderBy]) : a[orderBy].localeCompare(b[orderBy])
  );
  return sorted;
}
function CustomTable(props) {
  const {
    rows,
    headCells,
    page,
    setPage,
    setShow,
    dataLength,
    ignoreColumns,
    showBatchAnalytics,
    emptyColumn,
    emptyCharacter,
    defaultOrder,
    defaultOrderBy,
    ref,
    percentageColumn,
    grandTotalColumns,
    clickable,
    showColor,
    open,
    setOpen,
    report,
    setReport,
    boldColumn,
    leftAlignColumn,
    showRowsPerPage,
    disableSortColumn,
    columnWidth,
    emptyInfo,
    moduleWisedata,
    noDataForUser,
    ...rest
  } = props;

  const [order, setOrder] = React.useState(defaultOrder ? defaultOrder : "asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy ? defaultOrderBy : "");
  const [selected] = React.useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(showRowsPerPage || 100);
  const [maxHeight, setMaxHeight] = React.useState(0);
  const heightRef = useRef(null);
  const localHeight = localStorage.getItem("heightUpdated");

  const handleRequestSort = (event, property) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(property);
  };
  useLayoutEffect(() => {
    const updateHeight = () => {
      const newHeight = document?.getElementById("table_height");
      const childHeight = newHeight?.clientHeight;
      setMaxHeight(childHeight);
    };

    updateHeight();
  }, []);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function extractValues(input) {
    const colonIndex = input.indexOf("_");
    if (colonIndex !== -1) {
      const valueBeforeColon = input.substring(0, colonIndex).trim();
      const valueAfterColon = input.substring(colonIndex + 1).trim();
      return valueAfterColon;
    } else {
      return {};
    }
  }

  const calculateTimeDifference = (str) => {
    const [startTime, endTime] = str?.split("and").map((time) => time.trim());
    const [startHours, startMinutes] = startTime?.split(":").map(Number);
    const [endHours, endMinutes] = endTime?.split(":").map(Number);

    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;

    const minuteDifference = Math.abs(totalEndMinutes - totalStartMinutes);
    const hourDifference = Math.floor(minuteDifference / 60);

    if (totalStartMinutes >= totalEndMinutes && hourDifference <= 4) {
      return false;
    }
    if (hourDifference <= 4) {
      return true;
    }
    return false;
  };

  const checkSingleChar = (str) => {
    const validPairs = ["BC", "CB", "AD", "DA", "BD", "DB", "CD", "DC", "DD"];
    if (str.length === 1 || validPairs.includes(str)) {
      return true;
    }
    return false;
  };

  const checkBatchSize = (str) => {
    const batchSize = parseInt(str);
    if (batchSize > 34 || batchSize < 25) {
      return true;
    }
    return false;
  };

  const checkAssessmentValue = (assessment, totalValue) => {
    if (typeof assessment === "string" && assessment.length > 0) {
      const assessmentValues =
        (assessment && assessment.split("/").map((val) => parseInt(val))) || [];
      const newValue = parseInt(totalValue);

      const isAnyValueLessThan80Percent = assessmentValues.some(
        (val) => val < newValue * 0.8
      );
      return isAnyValueLessThan80Percent;
    }
  };

  const checkIfRatiosIsLow = (str, ogKey, keysToCompare) => {
    if (typeof str === "string") {
      let newBatch = str?.split("/");
      let bool;
      if (parseInt(newBatch[0]) < parseInt(newBatch[1])) {
        bool = true;
      } else {
        bool = false;
      }
      return bool && ogKey == keysToCompare;
    }
  };

  const showWarning = (data, ogKey, row) => {
    switch (ogKey) {
      case "Assessment Completion": {
        return checkIfRatiosIsLow(data, ogKey, "Assessment Completion");
      }
      case "MT Pair Grade": {
        return checkSingleChar(data);
      }
      case "Class Engagement": {
        return checkAssessmentValue(data, row["Batch Size"]);
      }
      case "Start and End Time": {
        return calculateTimeDifference(data);
      }
      case "Batch Size": {
        return checkBatchSize(data);
      }
      default:
        return false;
    }
  };

  const getCellContent = (row, head) => {

    if (head.originalKey !== "batchReport")
      return (
        <div
          className="new"
          style={
            showColor?.[head.originalKey]
              ? {
                background:
                  showColor[head.originalKey][row?.[head.originalKey]],
                width: "24px",
                height: "24px",
                borderRadius: "50%",
              }
              : boldColumn?.includes(head.originalKey)
                ? { fontSize: "0.9rem" }
                : { fontSize: "0.9rem" }
          }
        >
          {showWarning(row[head.originalKey], head.originalKey, row) ? (
            <strong style={{ color: "red", marginRight: "10px" }}>!</strong>
          ) : (
            <></>
          )}
          {emptyColumn?.includes(head.originalKey) &&
            row[head.originalKey] === emptyInfo
            ? emptyCharacter
            : showColor?.[head.originalKey]?.[row?.[head.originalKey]]
              ? emptyCharacter
              : head.originalKey == "Batch name"
                ? extractValues(row[head.originalKey])
                : ((row[head.originalKey]?.length || row[head.originalKey]) ? row[head.originalKey] : (!(head?.numeric)) ? '0' : '')}
          {percentageColumn?.includes(head.originalKey) &&
            row[head.originalKey] !== emptyInfo
            ? "%"
            : ""}
        </div>
      );
    else {
      return (
        <div
          style={
            showColor?.[head.originalKey]
              ? {
                background:
                  showColor[head.originalKey][row?.[head.originalKey]],
                width: "24px",
                height: "24px",
                borderRadius: "50%",
              }
              : boldColumn?.includes(head.originalKey)
                ? {
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }
                : {
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                }
          }
          onClick={() => {
            if (!report) {
              setReport(row["Master Trainer Name"]);
            }
          }}
        >
          Click to view more
        </div>
      );
    }
  };

  const TableRowComponent = ({ isItemSelected, row, index }) => {
    return (
      <TableRow
        hover
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={`${index}`}
        selected={isItemSelected}
      >
        {headCells
          .filter((headCell) => !ignoreColumns.includes(headCell.label))
          .map((head, hIndex) => (
            <TableCell
              key={hIndex}
              id="table_height"
              ref={heightRef}
              style={{
                color: "#3F3F3F",
                lineHeight: "1.3",
                textAlign: head.numeric ? "left" : "center",
                fontStyle: hIndex == 0 ? "bold" : "",
                height: "100%",
              }}
            >
              <>
                {typeof row?.[head.originalKey] !== "object" || head.originalKey === "batchReport" ? (
                  <div
                    className=""
                    style={
                      showColor?.[head.originalKey]?.[row?.[head.originalKey]]
                        ? {
                          width: "fit-content",
                          border: "2px solid #EBDEDE",
                          padding: "5%",
                          borderRadius: "50%",
                          // marginRight: "25%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }
                        : {
                          fontWeight: hIndex == 0 ? "bold" : "",
                          width: showColor?.[head.originalKey]?.[
                            row?.[head.originalKey]
                          ]
                            ? "fit-content"
                            : columnWidth?.[head.label],
                          marginRight: showColor?.[head.originalKey]?.[
                            row?.[head.originalKey]
                          ]
                            ? "45%"
                            : head.numeric
                              ? "0"
                              : "25%",
                        }
                    }
                  >
                    {getCellContent(row, head)}
                  </div>
                ) : (

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: `${localHeight}px`,
                      gap: "3rem",
                      justifyContent: "space-around",
                    }}
                    rowSpan={4}
                  >
                    {row[head.originalKey]?.length &&
                      row[head.originalKey]?.map((data) => {
                        return <div>{data}</div>;
                      })}
                  </div>
                )}
              </>
            </TableCell>
          ))
        }
      </TableRow >
    );
  };

  const CTable = ({ }) => {
    return (
      <div style={{}}>
        <Table
          stickyHeader
          sx={{ minWidth: 750, top: "0px" }}
          aria-labelledby="tableTitle"
          className="custom-table"
        >
          <CustomTablehead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length || 1}
            headCells={headCells}
            ignoreHeads={ignoreColumns}
            showColor={showColor}
            boldColumn={boldColumn}
            disableSortColumn={disableSortColumn || []}
            columnWidth={columnWidth || {}}
          />
          <TableBody>
            {stableSort(rows || [], order, orderBy, headCells)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRowComponent
                    key={'A' + index}
                    isItemSelected={isItemSelected}
                    row={row}
                    index={index}
                  />
                );
              })}
            {grandTotalColumns?.length > 0 && (
              <TableRow
                hover
                onClick={(event) => { }}
                tabIndex={-1}
                className="custom-table-head"
              >
                <TableCell
                  style={{
                    color: "#3F3F3F",
                    fontWeight: "bold",
                    lineHeight: "1",
                  }}
                >
                  Total
                </TableCell>
                {headCells.map((cell, index) => {
                  if (cell?.originalKey == "batchReport") return <></>;
                  else {
                    return index !== 0 ?
                      <TableCell
                        key={'T' + index}
                        style={{ color: "#3F3F3F", fontWeight: "bold", lineHeight: "1", }}
                      >
                        <div style={{ width: '100%', textAlign: 'center' }}>{grandTotalColumns?.includes(cell.originalKey)
                          ? rows.reduce((total, currentValue) => {
                            return (
                              total +
                              (currentValue[cell.originalKey] != ""
                                ? parseInt(currentValue[cell.originalKey])
                                : 0)
                            );
                          }, 0)
                          : ""}<ArrowUpwardOutlinedIcon style={{ color: "transparent" }} /></div>
                      </TableCell>
                      : undefined;
                  }
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div >
    );
  };

  return (
    <div className="custom-table-wrapper">
      {!report && rows?.length > 0 && <TablePagination
        rowsPerPageOptions={[25, 50, 75, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
      {rows?.length > 0 ? <TableContainer {...rest} className="table-div tableDiv">
        <CTable />
      </TableContainer> : <div style={{ fontSize: '1.1rem', padding: 10, textAlign: 'center', fontWeight: 500 }}>{noDataForUser}</div>}
    </div>
  );
}

CustomTable.propTypes = {
  ignoreColumns: PropTypes.arrayOf(PropTypes.string),
};

CustomTable.defaultProps = {
  ignoreColumns: [],
};

export default CustomTable;

// https://www.figma.com/file/DnS3IVB1c1iU8WW9dRUxj3/Analytics.pedgog.in?type=design&node-id=0-1&mode=design&t=TImqL13ZAXqDUP7L-0
