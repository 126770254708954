import {
	LOGGED_IN,
	LOGGED_OUT,
	SET_MENU,
	CLOSE_ALERT,
	ALERT
} from "../constants/app.constant";
import { getItem, setItem, clearAll } from '../utils/storage';

const user = getItem('user', true) || {};
const parentData = getItem('parentData', true) || {};
const view = getItem('view', false) || {};

export const initialState = {
	isLoggedIn: Object.keys(user).length > 0,
	user,
	menu: {},
	parentData: parentData || null,
	view: view || null,
	alert: {
		canShow: false,
		message: '',
		title: '',
	}
};

const appReducer = (state, action) => {
	switch (action.type) {
		case LOGGED_IN:
			setItem('access_token', action?.payload?.tokens?.access?.token);
			setItem('refresh_token', action?.payload?.tokens?.refresh?.token);
			setItem('user', JSON.stringify(action?.payload?.user));
			return { ...state, isLoggedIn: true, user: action?.payload?.user, };
		case "PARENTDATA":
			setItem('parentData', JSON.stringify(action?.payload?.parentData));
			return { ...state, parentData: action?.payload?.parentData, };
		case LOGGED_OUT:
			clearAll();
			return { ...state, isLoggedIn: false, user: {}, menu: {}, parentData: null };
		case SET_MENU:
			return { ...state, menu: action.payload };
		case ALERT:
			return { ...state, alert: { canShow: true, ...action.payload } };
		case CLOSE_ALERT:
			return { ...state, alert: initialState.alert };
		case 'View':
			setItem('view', action?.payload?.view);
			return { ...state, view: action.payload.view };
		default:
			return initialState;
	}
}

export default appReducer;